import NotFound from "../pages/NotFound";

const routes: any = [
  {
    path: "/",
    element: <NotFound />,
    title: "Home",
  },
  {
    path: "/*",
    element: <NotFound />,
  },
];

export default routes;
