import logo from '../../assets/images/logo.png';
import './not-found.css';

const NotFound = () => {

    return(
        <div className='not-found-wrapper'>
            <img src={logo} className='not-found-img' alt='PetPair Not Found'/>
            <div className='not-found-root'>
                <div>
                <h2 className='not-found-h1'>Goodbye and thanks from the PetPair team!</h2>
                </div>
                <div className='not-text-container'>
                    <p>After more than a year of operations and more tail wags than we can count, PetPair is sadly closing its doors. Our team is beyond proud of the experience we delivered for our partner shelters across Texas and California and more importantly for the happy tails of our rescue dogs who found adoptive homes. </p>
                    <p>We want to specifically thank the people who made this possible: our incredible volunteer fosters who cared for dozens of at-risk dogs; our partner shelter volunteers and coordinators; and the incredible team of developers, operators, marketers, and dog-lovers who helped hundreds of dogs find their forever homes.</p>
                    <p>Finally, make sure to support your local shelters. Sign up to foster. And if you’re ever looking for a new furry companion for your household, adopt, don’t shop.</p>
                    <br />
                </div>
                <div className='signature-container'>
                <p className='not-found-signature'>Sincerely,<br/>Daniel and Aanandh<br/>Cofounders, PetPair</p>
                </div>
            </div>
        </div>
    );

}

export default NotFound;